import React from "react";
// import FeaturePanel from "../../../HOC/Features";
import VideoPlayer from "../../../HOC/VideoPlayer";
import Shape from "../../../Styles/StyleTriangle";

import piggy from "../../../assets/images/savecash.png";

import Video from "../../../assets/images/Video/Joe_Fazzalari_Testimonial_Video.mp4";
import Video2 from "../../../assets/images/Video/Linda_Lombard_Video_Testimonial.mp4";
import Video3 from "../../../assets/images/Video/Tom_Keith_Testimonial.mp4";

const Features = (props) => {
  return (
    <div className="section grey-bg" id={props.id}>
      <div className="container-lg">
        <div
          className="row align-items-center justify-content-center"
          style={{
            marginBottom: "80px",
            paddingBottom: "80px",
            paddingTop: "80px",
          }}
        >
          <div className="section-title text-center text-upper mb-3">
            <h3>
              <span>Save Money</span> and Embrace <span>Sustainability</span>{" "}
              with Steadfast Solar!
            </h3>
          </div>
          <p>
            At Steadfast Solar, we understand the importance of making smart
            financial decisions while also prioritizing sustainability. By
            choosing solar energy for your home or business, you're not only
            making an environmentally conscious choice but also a financially
            savvy one. Here's why choosing solar with Steadfast Solar can save
            you money and the benefits of selecting our company:
          </p>
          <div className="text-start">
            <ul className="list-bullets">
              <li className="mb-2">
                Cost Savings: Solar energy allows you to generate your own
                electricity, reducing your reliance on traditional utility
                providers. As a result, you can significantly lower or even
                eliminate your monthly electricity bills. By investing in solar
                panels, you lock in a fixed energy cost for years to come,
                protecting yourself from rising utility rates.
              </li>

              <li className="mb-2">
                Return on Investment (ROI): Going solar is a wise financial
                investment with a high potential for return. With various
                financial incentives available, such as tax credits, rebates,
                and net metering, you can recoup your initial investment faster.
                Additionally, solar panels have a long lifespan, typically
                lasting 25 to 30 years, ensuring a consistent ROI over the
                system's lifetime.
              </li>

              <li className="mb-2">
                Energy Independence: By producing your own clean and renewable
                energy, you become less dependent on the grid. This independence
                provides stability and protection against fluctuating energy
                prices, grid outages, and other utility-related issues. With
                solar, you take control of your energy future.
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-6 col-lg-7 text-center mb-30">
            <div className="text-start">
              <ul className="list-bullets">
                <li className="mb-2">
                  Environmental Benefits: Choosing solar power reduces your
                  carbon footprint and helps combat climate change. Solar energy
                  is clean, renewable, and produces no greenhouse gas emissions
                  during operation. By going solar, you actively contribute to a
                  healthier and more sustainable planet for future generations.
                </li>

                <li className="mb-2">
                  Quality and Expertise: At Steadfast Solar, we pride ourselves
                  on delivering top-quality solar solutions. Our team of
                  experienced professionals ensures precise system design,
                  seamless installation, and meticulous attention to detail. We
                  only work with trusted manufacturers to provide you with the
                  most efficient and reliable solar panels and equipment.
                </li>

                <li className="mb-2">
                  Exceptional Customer Service: We prioritize customer
                  satisfaction and aim to provide a seamless experience from
                  start to finish. Our dedicated team will guide you through the
                  entire process, from initial consultation to post-installation
                  support. We are committed to addressing your unique needs and
                  ensuring you have a positive and rewarding solar journey.
                </li>
              </ul>
              <br />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-5 text-center mb-30">
            <img src={piggy} alt={piggy} className="img-fluid" />
          </div>

          <p>
            At Steadfast Solar, we firmly believe that choosing solar power not
            only benefits your wallet but also contributes to a sustainable
            future. Join us in the renewable energy revolution and let us help
            you harness the power of the sun while saving you money. Contact us
            today to learn more about our solar solutions and how we can tailor
            them to your specific needs.
          </p>
        </div>
        {/* <div className="section-title text-center text-upper">
          <h3>
            OUR <span>SERVICES</span>
          </h3>
        </div>
        <div
          className="row align-items-stretch justify-content-center h-100"
          style={{ marginBottom: "130px" }}
        >
          <div className="col-12 col-sm-6 col-xl-3 text-center">
            <FeaturePanel img={IMG1} link="residential-solar">
              RESIDENTIAL SOLAR
            </FeaturePanel>
          </div>
          <div className="col-12 col-sm-6 col-xl-3 text-center">
            <FeaturePanel img={IMG2} link="">
              COMMERCIAL SOLAR
            </FeaturePanel>
          </div>
          <div className="col-12 col-sm-6 col-xl-3 text-center">
            <FeaturePanel img={IMG3} link="">
              BUILDERS
            </FeaturePanel>
          </div>
          <div className="col-12 col-sm-6 col-xl-3 text-center">
            <FeaturePanel img={IMG4} link="">
              ACCREDITED PARTNER PROGRAM
            </FeaturePanel>
          </div>
        </div> */}
        <div className="row align-items-center justify-content-center mb-120">
          <div className="section-title mb-40 text-center">
            <h3 className="">
              Here from a few our
              <br />
              <span className="text-secondary">
                <strong>Customers</strong>
              </span>
            </h3>
          </div>
          <div className="col-12 col-md-6 mb-30 mt-20">
            <h2 className="title mb-30">
              Joe
              <br />
              <span>
                <strong>Aldinga Beach</strong>
              </span>
            </h2>
            <div className="img-shadow">
              <VideoPlayer src={Video} title="Steadfast Solar" />
            </div>
          </div>
          <div className="col-12 col-md-6 mb-30 mt-20">
            <h2 className="title mb-30">
              Thomas
              <br />
              <span>
                <strong>Two Wells</strong>
              </span>
            </h2>
            <div className="img-shadow">
              <VideoPlayer src={Video3} title="Steadfast Solar" />
            </div>
          </div>
          <div className="col-12 col-md-6 mb-30 mt-20">
            <h2 className="title mb-30">
              Linda
              <br />
              <span>
                <strong>Port Noarlunga South</strong>
              </span>
            </h2>
            <div className="img-shadow">
              <VideoPlayer src={Video2} title="Steadfast Solar" />
            </div>
          </div>
        </div>
      </div>
      <Shape left={true} white={true} />
    </div>
  );
};

export default Features;
