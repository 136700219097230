import React, { useEffect, useState } from "react";
import { animateScroll as scroller } from "react-scroll";
import B2t from "../../Components/B2t/B2t";
import IconFeatures from "../../Components/IconsFeatures/IconsFeatures";
import Intro from "../../Components/Intro/Intro";
import Products from "../../Components/Products/Products";
import Body from "./Body/Body";
import NoFinance from "./Body/NoFinance";
const Commercial = (props) => {
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (!firstLoad) {
      scroller.scrollTo(0, {
        duration: 200,
        delay: 0,
        smooth: "easeInOutQuart",
      });
      setFirstLoad(true);
    }
  }, [firstLoad]);
  return (
    <div style={{overflow: 'hidden'}}>
      <B2t show={props.show} />
      <Intro title="COMMERCIAL SOLAR" subtitle="" id="home" color="grey" />
      <Body />
      <IconFeatures />
      <NoFinance />
      <Products color="white" heading={true} title="Our " span="Products" />
    </div>
  );
};

export default Commercial;
