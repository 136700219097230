import React, { useEffect, useState } from "react";
import { animateScroll as scroller } from "react-scroll";
import B2t from "../../Components/B2t/B2t";
import Products from "../../Components/Products/Products";
import Support from "../../Components/Support/Support";
import Toasts from "../../Components/Toasts/Toasts";
import Features from "./Features/Features";
import Intro from "./Intro/Intro";

const Main = (props) => {
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (!firstLoad) {
      scroller.scrollTo(0, {
        duration: 200,
        delay: 0,
        smooth: "easeInOutQuart",
      });
      setFirstLoad(true);
    }
  }, [firstLoad]);
  return (
    <div className="main-wrapper" style={{overflow: 'hidden'}}>
      <Toasts
        onMailSend={props.onMailSend}
        show={props.sent}
        onMailSendError={props.onMailSendError}
        sentError={props.sentError}
      />
      <B2t show={props.show} />
      <Intro
        id="home"
        onMailSend={props.onMailSend}
        onMailSendError={props.onMailSendError}
      />
      <Features id="services" />
      <Support id="support" />
      <Products color="grey" heading={true} title="Our " span="Products" />
    </div>
  );
};

export default Main;
