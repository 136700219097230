import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Scroll from "react-scroll";

import Logo from "../../assets/images/logo.png";
import Qbutton from "../../assets/images/quote.png";

const Links = Scroll.Link;

const Heading = (props) => {
  const [expanded, setExpanded] = useState(false);

  const NavLinks = () => {
    if (
      !document.location.href.endsWith("contactus") &&
      !document.location.href.endsWith("about") &&
      !document.location.href.endsWith("builders") &&
      !document.location.href.endsWith("accredited-solar-partners") &&
      !document.location.href.endsWith("commercial-solar") &&
      !document.location.href.endsWith("residential-solar") &&
      !document.location.href.endsWith("denied")
    ) {
      return (
        <>
          <Links
            to="home"
            className="nav-link"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onClick={closeNav}
          >
            Home
          </Links>
          <Links
            to="services"
            className="nav-link"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            onClick={closeNav}
          >
            Services
          </Links>
          <Link to="/residential-solar" className="nav-link" onClick={closeNav}>
            Residential Solar
          </Link>
          <Link to="/commercial-solar" className="nav-link" onClick={closeNav}>
            Commercial Solar
          </Link>
          <Link to="/builders" className="nav-link" onClick={closeNav}>
            Builders
          </Link>
          <Link
            to="/accredited-solar-partners"
            className="nav-link"
            onClick={closeNav}
          >
            Accredited Solar Partners
          </Link>
          <Link to="/about" className="nav-link" onClick={closeNav}>
            About
          </Link>
          <Link to="/contactus" className="nav-link" onClick={closeNav}>
            Contact Us
          </Link>
        </>
      );
    } else {
      return (
        <>
          <Link className="nav-link" to="/" onClick={closeNav}>
            Home
          </Link>
          <Link to="/residential-solar" className="nav-link" onClick={closeNav}>
            Residential Solar
          </Link>
          <Link to="/commercial-solar" className="nav-link" onClick={closeNav}>
            Commercial Solar
          </Link>
          <Link to="/builders" className="nav-link" onClick={closeNav}>
            Builders
          </Link>
          <Link
            to="/accredited-solar-partners"
            className="nav-link"
            onClick={closeNav}
          >
            Accredited Solar Partners
          </Link>
          <Link to="/about" className="nav-link" onClick={closeNav}>
            About
          </Link>
          <Link to="/contactus" className="nav-link" onClick={closeNav}>
            Contact Us
          </Link>
        </>
      );
    }
  };
  const navToggle = () => {
    setExpanded(expanded ? false : true);
  };

  const closeNav = () => {
    setExpanded(false);
  };
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      expanded={expanded}
      className={`${props.navbar} ${expanded ? "nav-expand-bg" : ""}`}
      fixed="top"
    >
      <Container>
        <Link className="navbar-brand" to="/">
          <img src={Logo} alt={Logo} className="img-fluid" />
        </Link>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          data-bs-toggle="collapse"
          data-bs-target="#responsive-navbar-nav"
          onClick={navToggle}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <NavLinks />
          </Nav>
        </Navbar.Collapse>
        <Link
          className="navbar-brand ms-3 d-none d-lg-block quote"
          to="/contactus"
        >
          <img src={Qbutton} alt={Qbutton} className="img-fluid" />
        </Link>
      </Container>
    </Navbar>
  );
};

export default Heading;
