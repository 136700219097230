import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

// import "notiflix/dist/notiflix-3.2.6.min.css";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

const ContactForm = (props) => {
  const [enable, setEnable] = useState(false);

  Loading.remove();
  const onSubmit = () => {
    const name = document.getElementById("name");
    const email = document.getElementById("email");
    const number = document.getElementById("number");

    const url = process.env.PUBLIC_URL + "/assets/mail/mail.php";
    setEnable(true);
    Loading.circle("Sending your request, Please Wait...");
    axios
      .post(
        url,
        JSON.stringify({
          name: name.value,
          email: email.value,
          number: number.value,
          submit: "send mail",
          headers: { "Content-Type": "application/json" },
        })
      )
      .then(function (response) {
        console.log(response.data);
        setEnable(false);
        Loading.remove();
        Notify.success(
          "Your request has been successfully sent, We will be in contact with you soon.",
          {
            ID: "MKAS",
            width: "330px",
            timeout: 5000,
            useIcon: true,
            showOnlyTheLastOne: true,
            borderRadius: "20px",
            cssAnimation: true,
            cssAnimationDuration: 500,
            cssAnimationStyle: "from-left",
          }
        );
        // props.onMailSend(true);
        // props.onMailSendError(false);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
        setEnable(false);
        Loading.remove();
        Notify.failure(
          "Request failed, You can find our contact info at the bottom of page",
          {
            ID: "MKA",
            width: "330px",
            timeout: 5000,
            useIcon: true,
            showOnlyTheLastOne: true,
            borderRadius: "20px",
            cssAnimation: true,
            cssAnimationDuration: 500,
            cssAnimationStyle: "from-left",
          }
        );
        // props.onMailSend(true);
        // props.onMailSendError(true);
      });
  };
  return (
    <div className={`section py-0 mt-${props.mt} mt-mb-0`}>
      <div className="contact-wrapper" style={{ zIndex: 999 }}>
        <div className="card-style rounded-20 py-3 bg-trans-white">
          <h4 className="text-bold mb-15 border-bottom pb-2">
            Contact Us
            <div className="text-sm text-dark text-light">
              Request a callback
            </div>
          </h4>
          <div className="buttons-group justify-content-center">
            <div className="form-boxes w-100">
              <div className="mx-3 w-100">
                <div className="input-style-3">
                  <label className="p-0 m-0 text-normal">Name:</label>
                  <input type="text" id="name" />
                  <span className="icon">
                    <i className="lni lni-user"></i>
                  </span>
                </div>
              </div>
              <div className="w-100 mx-3">
                <div className="input-style-3">
                  <label className="p-0 m-0 text-normal">Contact Email:</label>
                  <input type="email" id="email" />
                  <span className="icon">
                    <i className="lni lni-envelope"></i>
                  </span>
                </div>
              </div>
              <div className="w-100 mx-3">
                <div className="input-style-3 mb-30">
                  <label className="p-0 m-0 text-normal">Contact Number</label>
                  <input type="phone" id="number" />
                  <span className="icon">
                    <i className="lni lni-mobile"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="buttons-group justify-content-end">
            <button
              className="btn primary-btn-outline rounded-full px-4 notiflix-loading"
              disabled={enable}
              onClick={() => onSubmit()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withParams(ContactForm);
