import { B2T, MAIL, MAILERROR, NAVBAR, TOGGLE } from "./constants";

export const setNavbar = (link) => ({
  type: NAVBAR,
  payload: link,
});

export const setToggle = (link) => ({
  type: TOGGLE,
  payload: link,
});

export const setB2T = (link) => ({
  type: B2T,
  payload: link,
});

export const setMailSend = (link) => ({
  type: MAIL,
  payload: link,
});
export const setMailSendError = (link) => ({
  type: MAILERROR,
  payload: link,
});
