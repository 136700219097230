import React, { useEffect, useState } from "react";
import { animateScroll as scroller } from "react-scroll";
import Advert from "../../Components/Advert/Advert";
import B2t from "../../Components/B2t/B2t";
import Intro from "../../Components/Intro/Intro";
import Products from "../../Components/Products/Products";
import Body from "./Body/Body";

const Resedential = (props) => {
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (!firstLoad) {
      scroller.scrollTo(0, {
        duration: 200,
        delay: 0,
        smooth: "easeInOutQuart",
      });
      setFirstLoad(true);
    }
  }, [firstLoad]);
  return (
    <div style={{overflow: 'hidden'}}>
      <B2t show={props.show} />
      <Intro title="RESIDENTIAL SOLAR" subtitle="" id="home" />
      <Body />

      <Advert title="Own Your Solar For $0 Upfront" shape="normal" foot={false}>
        <p>
          At Steadfast Solar we are Empowering our Customers, by making it even
          easier for Every Australian to be able to have a Solar System
          installed at their house, with no out of pocket money. Our $0 Upfront
          Solar Solution allows our Customers to have a Solar System installed
          and to pay off the system from the savings generated from the
          electricity purchased. In most cases, your electricity savings are
          more than the Solar System repayments, meaning you’re actually
          spending less money that you were before!
        </p>
        <br />
        <p>
          With Steadfast Solar there is no need to miss out on the benefits of
          Solar, or having to compromise on having the Best Quality Solar System
          installed, due to finances. Contact Steadfast Solar now so that YOU
          can start enjoying the benefits of owning a solar system for $0
          upfront today.
        </p>
      </Advert>
      <Products color="grey" heading={true} title="Our " span="Products" />
    </div>
  );
};

export default Resedential;
