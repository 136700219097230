import React, { useEffect, useState } from "react";
import { animateScroll as scroller } from "react-scroll";
import B2t from "../../Components/B2t/B2t";
import Intro from "../../Components/Intro/Intro";
import Products from "../../Components/Products/Products";
import Content from "./Body/Content";

const Builders = (props) => {
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (!firstLoad) {
      scroller.scrollTo(0, {
        duration: 200,
        delay: 0,
        smooth: "easeInOutQuart",
      });
      setFirstLoad(true);
    }
  }, [firstLoad]);
  return (
    <div style={{overflow: 'hidden'}}>
      <B2t show={props.show} />
      <Intro title="BUILDERS" subtitle="" id="home" color="white" />
      <Content />
      <Products color="grey" heading={true} title="Our " span="Products" />
    </div>
  );
};

export default Builders;
