import React from "react";
import Section from "../../../HOC/Section";
import Image3 from "../../../assets/images/Solar/1G2A8979.jpg";
import Image from "../../../assets/images/Solar/DJI_0683.JPG";
import Image2 from "../../../assets/images/piggy.png";

const RSBody = (props) => {
  return (
    <Section
      color="grey"
      size="lg"
      shape="tilt"
      left={true}
      add={false}
      white={true}
    >
      <div className="row align-items-center justify-content-center mb-60">
        <div className="col-12 col-md-7">
          <h2 className="title mb-30 text-upper">
            Commercial <span>Solar</span> Systems
          </h2>
          <p>
            Steadfast Solar is South Australia’s go-to name for Commercial
            Solar. We’ve developed an industry reputation for providing the
            highest standard of Commercial Solar systems, on time and on budget.
            Steadfast Solar is the leading Commercial Solar specialist in the
            state, working with the most experienced and accredited experts in
            the Solar industry.
          </p>
          <br />
          <p>
            If you’re running a business and are considering Solar, call
            Steadfast Solar, and we’ll come out, listen to what you need, and
            discuss a Solar System specifically to meet your requirements
          </p>
        </div>
        <div className="col-12 col-md-5 mt-20 mb-30">
          <div className="border-image img-border-invert img-shadow">
            <img src={Image} alt={Image} className="img-fluid" />
          </div>
        </div>
        <div className="col-12 col-md-5">
          {/* <div className="border-image img-border img-shadow-invert"> */}
          <img src={Image2} alt={Image2} className="img-fluid" />
          {/* </div> */}
        </div>
        <div className="col-12 col-md-7 mt-20 mb-30">
          <p>
            Our success comes from saving you money, so we’ll arrange the right
            Commercial Solar system needed for your business.
          </p>
          <p>
            At Steadfast Solar we have helped businesses across South Australia
            understand the benefits of Solar for their business and have
            provided specifically tailored solar systems for them
          </p>
          <p>
            Here’s why you should consider moving your business over to solar
            power:
          </p>
        </div>
        <div className="col-12 col-md-7 mt-20 mb-30">
          <p>
            And after you have paid off the initial investment in the system,
            that’s money in your pocket every month if you have no power bill or
            a subsidised power bill.
          </p>
          <p>
            Your initial investment can be further reduced – thanks to the
            Government rebate program. With huge incentives to solar power
            users, the program drastically cuts the cost of a new solar system.
            It pays you in advance for the projected power usage of your system.
          </p>
        </div>
        <div className="col-12 col-md-5 mt-20 mb-30">
          <div className="border-image img-border-invert img-shadow">
            <img src={Image3} alt={Image3} className="img-fluid" />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default RSBody;
