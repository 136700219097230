import React from "react";
import Section from "../../../HOC/Section";
import I4 from "../../../assets/images/clock.svg";
import I2 from "../../../assets/images/ecology-and-environment.svg";
import I3 from "../../../assets/images/ecology.svg";
import I1 from "../../../assets/images/money-1.svg";
import I6 from "../../../assets/images/money-2.svg";
import I5 from "../../../assets/images/return-of-investment.svg";

const NoFinance = (props) => {
  return (
    <Section color="grey" shape="normal" white={true}>
        <div className="section-title text-center text-upper mb-5">
          <h2>
            Steadfast Solar’s <br/>
            <span>Zero Deposit</span>, No <span>Financials Required</span> Finance Offer
          </h2>
        </div>
        <div className="row align-items-center justify-content-center mb-60">
          <div className="col-12 col-md-6 mb-30">
            <div className="d-flex align-items-center">
              <div className="comm-img">
                <img src={I6} alt={I6} />
              </div>
              <h5 className="text-medium">YOU NEED NOT PAY A CENT TODAY</h5>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-30">
            <div className="d-flex align-items-center">
              <div className="comm-img">
                <img src={I4} alt={I4} />
              </div>
              <h5 className="text-medium">
                THE PAYBACK PERIOD IS LIKELY TO BE WITHIN 1.5 – 3.0 YEARS
              </h5>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-30">
            <div className="d-flex align-items-center">
              <div className="comm-img">
                <img src={I1} alt={I1} />
              </div>
              <h5 className="text-medium">
                PLANS ARE AVAILABLE WITH INTEREST LOW INTEREST RATES
              </h5>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-30">
            <div className="d-flex align-items-center">
              <div className="comm-img" style={{ fontSize: "110px" }}>
                <img src={I2} alt={I2} />
              </div>
              <h5 className="text-medium">
                YOUR SOLAR SYSTEM WILL GENERATE ENERGY DURING MOST BUSINESSES’
                PEAK CONSUMPTION PERIODS
              </h5>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-30">
            <div className="d-flex align-items-center">
              <div className="comm-img" style={{ fontSize: "80px" }}>
                <img src={I3} alt={I3} />
              </div>
              <h5 className="text-medium">
                REPAYMENTS ARE LIKELY TO BE COMPLETELY OFFSET BY FORECASTED
                SAVINGS ON ELECTRICITY BILLS
              </h5>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-30">
            <div className="d-flex align-items-center">
              <div className="comm-img">
                <img src={I5} alt={I5} />
              </div>
              <h5 className="text-medium">
                YOU WILL ENJOY A GUARANTEED RETURN-ON-INVESTMENT
              </h5>
            </div>
          </div>
        </div>
    </Section>
  );
};

export default NoFinance;
