import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Favicon from "../../assets/images/favicon.png";

const Toasts = (props) => {
  return (
    <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1100 }}>
      <Toast
        onClose={() => props.onMailSend(false)}
        show={props.show}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <img
            src={Favicon}
            alt={Favicon}
            className="rounded me-2"
            style={{ height: "30px" }}
          />
          <h5 className="me-auto text-active">
            {props.sentError ? "Error Occurred" : "Request Sent"}
          </h5>
          {/* <small className="text-muted">just now</small> */}
        </Toast.Header>
        <Toast.Body>
          {props.sentError
            ? "An error occurred send request. Please see contact details at bottom of page."
            : "Request sent successfully. We will be in touch soon!."}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default Toasts;
