import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroller } from "react-scroll";

import Advert from "../../Components/Advert/Advert";
import B2t from "../../Components/B2t/B2t";
import Intro from "../../Components/Intro/Intro";
import Products from "../../Components/Products/Products";
import Section from "../../HOC/Section";
import contactus from "../../assets/images/about/Contact-Us.png";
import headimage from "../../assets/images/Solar/1G2A8932.jpg";

const About = (props) => {
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (!firstLoad) {
      scroller.scrollTo(0, {
        duration: 200,
        delay: 0,
        smooth: "easeInOutQuart",
      });
      setFirstLoad(true);
    }
  }, [firstLoad]);
  return (
    <div className="about" style={{overflow: 'hidden'}}>
      <B2t show={props.show} />
      <Intro title="About" subtitle="Steadfast Solar" id="home" color="white" />
      <Section shape="tilt" add={true} white={false} color="white" size="lg">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6 mb-30">
            <div className="about-img">
              <img src={headimage} className="img-fluid" alt="Ash Portrait" />
            </div>
          </div>
          <div className="col-12 col-md-6 mb-30">
            <h1>
              Unleashing Solar{" "}
              <span className="text-secondary text-upper">Brilliance!</span>
            </h1>
            <h4 className="mb-3">
              Empowering a Sustainable Future with{" "}
              <span className="text-secondary">Steadfast Solar</span>
            </h4>
            <p className="mb-3">
              Welcome to Steadfast Solar, where we harness the power of the sun
              to illuminate a brighter future. With our unwavering commitment to
              excellence, we are proud to be your trusted partner in the
              transition towards clean and sustainable energy solutions.
            </p>
            <p className="mb-3">
              At Steadfast Solar, we understand the significance of making
              informed decisions when it comes to solar energy. As one of the
              leading providers of commercial and residential solar solutions,
              we are dedicated to offering you the best prices without
              compromising on quality. Our mission is to empower businesses and
              homeowners with the tools they need to embrace a greener tomorrow
              while reducing their carbon footprint.
            </p>
            <p className="mb-3">
              What sets us apart is not just our commitment to delivering
              exceptional solar products; it's the unparalleled expertise and
              professionalism of our team. Each member of our staff is a
              seasoned professional in their respective field, bringing a wealth
              of knowledge and experience to every project we undertake. From
              our skilled engineers and technicians to our attentive customer
              service representatives, we take pride in assembling a team that
              exceeds expectations.
            </p>
            <p className="mb-3">
              Our partners are at the heart of our success, and their dedication
              to the solar industry is unrivaled. Behind the scenes, we have
              cultivated strategic partnerships with leading manufacturers,
              ensuring that we can provide you with cutting-edge solar
              technologies that maximize efficiency and durability. These
              partnerships allow us to offer a diverse range of solar solutions
              tailored to meet your specific needs, whether it's for a
              commercial building or a residential property.
            </p>
            <p className="mb-3">
              As you navigate through our website, you will discover our
              commitment to transparency, quality craftsmanship, and
              unparalleled customer service. We believe in open and honest
              communication, guiding you through every step of the process, from
              initial consultation to installation and beyond. Our goal is to
              not only provide you with a solar system that exceeds your
              expectations but also to build lasting relationships with our
              clients.
            </p>
            <p>
              Thank you for considering Steadfast Solar as your solar energy
              partner. Together, let's embrace the power of the sun and
              illuminate a brighter, sustainable future for generations to come.
            </p>
          </div>
        </div>
        <div className="col-12 col-md-8 d-flex mx-auto">
          <div className="container gradient-bg border-radius-50 pt-10 pb-10">
            <div className="contact-frame text-center text-white py-3">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-md-6 mb-20">
                  <h2>
                    Contact Us <br />
                    <span>Today!</span>
                  </h2>
                  <div className="mt-20 z-index">
                    <Link
                      to="/contactus"
                      className="btn status-btn secondary-btn-outline btn-hover rounded-20 px-5"
                    >
                      Contact
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-md-5 mb-20 mr-auto">
                  <img src={contactus} alt={contactus} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Advert
        title="Own Your Solar For $0 Upfront"
        shape="normal"
        foot={false}
        white={true}
      >
        <p>
          At Steadfast Solar we are Empowering our Customers, by making it even
          easier for Every Australian to be able to have a Solar System
          installed at their house, with no out of pocket money. Our $0 Upfront
          Solar Solution allows our Customers to have a Solar System installed
          and to pay off the system from the savings generated from the
          electricity purchased. In most cases, your electricity savings are
          more than the Solar System repayments, meaning you’re actually
          spending less money that you were before!
        </p>
        <br />
        <p className="mb-30">
          With Steadfast Solar there is no need to miss out on the benefits of
          Solar, or having to compromise on having the Best Quality Solar System
          installed, due to finances. Contact Steadfast Solar now so that YOU
          can start enjoying the benefits of owning a solar system for $0
          upfront today.
        </p>
      </Advert>
      <Products color="white" heading={true} title="Our " span="Products" />
    </div>
  );
};

export default About;
