import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/skyblue";
import React, { useEffect, useState } from "react";
import Shape from "../../Styles/StyleFooter";
import p1 from "../../assets/images/products/image-1.png";
import p2 from "../../assets/images/products/image-2.png";
import p3 from "../../assets/images/products/image-3.png";
import p4 from "../../assets/images/products/image-4.png";
import p5 from "../../assets/images/products/image-5.png";
import {
  default as p12,
  default as p6,
} from "../../assets/images/products/image-6.png";
import p7 from "../../assets/images/products/image-7.png";
import p11 from "../../assets/images/products/image.png";
import p9 from "../../assets/images/products/unnamed-1.png";
import p10 from "../../assets/images/products/unnamed-2.png";
import p8 from "../../assets/images/products/unnamed.png";

const Products = (props) => {
  const [items, setItems] = useState(2);

  const testiMonials = [p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12];
  const Image = (props) => {
    return (
      <div className="item">
        <div className="shadow-effect">
          <img className="img-circle" src={props.img} alt={props.img} />
        </div>
      </div>
    );
  };
  useEffect(() => {
    var width = window.innerWidth;
    console.log(width);
    if (width > 768 && width < 992) {
      setItems(2);
    } else if (width > 992 && width < 1200) {
      setItems(3);
    } else if (width > 1200) {
      setItems(5);
    } else {
      setItems(1);
    }
  }, []);

  return (
    <div
      className={`position-relative pt-4 ${props.color}-bg`}
      style={{ paddingBottom: "110px" }}
    >
      <div className="container-lg">
        {props.heading ? (
          <div className="section-title text-center text-upper mb-5">
            <h2>
              {props.title} <span>{props.span}</span>
            </h2>
          </div>
        ) : null}
        <Splide
          aria-label="Our Products"
          hasTrack={false}
          options={{
            autoplay: true,
            lazyLoad: true,
            wheel: true,
            keyboard: true,
            interval: 2500,
            easing: "ease-in-out",
            perMove: 1,
            perPage: items,
            speed: 850,
            gap: 30,
            type: "loop",
            pauseOnHover: false,
            resetProgress: false,
            pagination: false,
            isNavigation: false,
          }}
        >
          <div style={{ position: "relative", textAlign: "center" }}>
            <SplideTrack>
              <SplideSlide>
                <Image img={testiMonials[0]} />
              </SplideSlide>
              <SplideSlide>
                <Image img={testiMonials[1]} />
              </SplideSlide>
              <SplideSlide>
                <Image img={testiMonials[2]} />
              </SplideSlide>
              <SplideSlide>
                <Image img={testiMonials[3]} />
              </SplideSlide>
              <SplideSlide>
                <Image img={testiMonials[4]} />
              </SplideSlide>
              <SplideSlide>
                <Image img={testiMonials[5]} />
              </SplideSlide>
              <SplideSlide>
                <Image img={testiMonials[6]} />
              </SplideSlide>
              <SplideSlide>
                <Image img={testiMonials[7]} />
              </SplideSlide>
              <SplideSlide>
                <Image img={testiMonials[8]} />
              </SplideSlide>
              <SplideSlide>
                <Image img={testiMonials[9]} />
              </SplideSlide>
              <SplideSlide>
                <Image img={testiMonials[10]} />
              </SplideSlide>
              <SplideSlide>
                <Image img={testiMonials[11]} />
              </SplideSlide>
            </SplideTrack>
          </div>
          <div className="splide__progress mt-30">
            <div className="splide__progress__bar" />
          </div>
        </Splide>
      </div>
      <Shape />
    </div>
  );
};

export default Products;
