import React, { useState } from "react";
import { Link } from "react-router-dom";
import ContactForm from "../../../Components/ContactForm/ContactForm";
import Style from "../../../Styles/Style";
import button from "../../../assets/images/contactus_button.png";
import buttonHover from "../../../assets/images/contactus_button_hover.png";
const Intro = (props) => {
  const [isShown, setIsShown] = useState(false);
  return (
    <div className="intro intro-overlay-5" id={props.id}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-7 mb-30">
            <div className="position-relative z-index mt-160 mt-mb-0">
              <h1 className="welcome">STEADFAST SOLAR</h1>
              <h1>STEADFAST SOLAR </h1>
              <p
                style={{
                  fontSize: "16px",
                  textShadow: "0 3px 15px var(--text-shadow)",
                }}
              >
                Let us help you take control of your Energy Bills so
                <br />
                you can take care of the most important things in life{" "}
              </p>
              <div
                className="mt-30 position-relative contactus-button"
                data-aos="flip-up"
                data-aos-duration="700"
                data-aos-delay="500"
              >
                <Link
                  to="/contactus"
                  onMouseEnter={() => setIsShown(true)}
                  onMouseLeave={() => setIsShown(false)}
                >
                  <img
                    src={buttonHover}
                    alt={buttonHover}
                    className={`img-fluid ${isShown ? "show" : "hidden"}`}
                  />
                  <img
                    src={button}
                    alt={button}
                    className={`img-fluid ${isShown ? "hidden" : "show"}`}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="d-none d-md-block col-3 offset-2 mb-30 mt-30">
            <ContactForm
              mt={220}
              onMailSend={props.onMailSend}
              onMailSendError={props.onMailSendError}
            />
          </div>
        </div>
      </div>
      <Style white={false} />
    </div>
  );
};

export default Intro;
