import React from "react";
import Section from "../../HOC/Section";
import I1 from "../../assets/images/support/diagnosis-new.svg";
import I2 from "../../assets/images/support/discussion-new.svg";
import I3 from "../../assets/images/support/paper-new.svg";
import I4 from "../../assets/images/support/support-new.svg";
const Support = (props) => {
  return (
    <Section size="sm" color="white" shape="tilt" left={false} white={false}>
      <div style={{ paddingBottom: "80px" }}>
        <div className="section-title text-center pb-4">
          <h3>
            HOW WE <span>WORK</span>
          </h3>
        </div>
        <div className="section-paragraph text-center pb-5">
          <p>
            As South Australia’s leading and most personalised solar power
            company, we manage solar systems in homes and businesses across
            South. Australia. Our customer-first approach to getting you
            connected with solar will ensure that you feel confident and
            supported every step of the way. The process involves:
          </p>
        </div>
        <div className="row justify-content-center pb-5">
          <div className="col-12 col-md-6 col-xl-3 text-center mb-2">
            <div className="aio-icon pb-2">
              <img src={I3} alt={I3} />
            </div>
            <div className="aio-title pb-2">
              <h4 className="text-upper">FEASIBILITY & PROPOSAL</h4>
            </div>
            <div className="aio-description">
              <p>
                A feasibility test is conducted before we deliver our
                competitive proposals as our goal is to save you money and make
                a difference in your
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3 text-center mb-2">
            <div className="aio-icon pb-2">
              <img src={I2} alt={I2} />
            </div>
            <div className="aio-title pb-2">
              <h4 className="text-upper">FREE CONSULTATION</h4>
            </div>
            <div className="aio-description">
              <p>
                With a free consultation, we’ll assess your home and business
                and determine the kind of solar system best-suited to your space
                and
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3 text-center mb-2">
            <div className="aio-icon pb-2">
              <img src={I1} alt={I1} />
            </div>
            <div className="aio-title pb-2">
              <h4 className="text-upper">DESIGN & APPROVAL</h4>
            </div>
            <div className="aio-description">
              <p>
                Following the guidelines of the Clean Energy Council, your solar
                system will be perfectly attuned to the South Australian
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3 text-center mb-2">
            <div className="aio-icon pb-2">
              <img src={I4} alt={I4} />
            </div>
            <div className="aio-title pb-2">
              <h4 className="text-upper">AFTER SALES SUPPORT</h4>
            </div>
            <div className="aio-description">
              <p>
                Your journey with Steadfast Solar doesn’t stop after the
                installation. For as long as you have your solar system
                installed, we’ll be there to help you make the most of
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Support;
